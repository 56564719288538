import React from "react";

const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      className="fill-none w-6 h-[1.5625rem]"
    >
      <path
        d="M20.9728 7.3424C20.5084 7.96192 19.947 8.50233 19.3103 8.94276C19.3103 9.10459 19.3103 9.26642 19.3103 9.43725C19.3154 12.3761 18.1415 15.1941 16.0518 17.2595C13.962 19.3249 11.1312 20.4649 8.19405 20.4239C6.49599 20.4296 4.81967 20.0419 3.29642 19.2911C3.21428 19.2552 3.16131 19.174 3.16162 19.0843V18.9854C3.16162 18.8563 3.26623 18.7517 3.39527 18.7517C5.06442 18.6966 6.67402 18.1179 7.99634 17.0974C6.48553 17.0669 5.12619 16.1719 4.5006 14.7957C4.46901 14.7206 4.47884 14.6343 4.52657 14.5682C4.57429 14.5021 4.653 14.4657 4.73425 14.4721C5.19342 14.5182 5.65718 14.4755 6.1002 14.3462C4.43239 14 3.17921 12.6154 2.99986 10.9207C2.99349 10.8394 3.02992 10.7607 3.096 10.7129C3.16207 10.6652 3.24824 10.6553 3.32338 10.687C3.77094 10.8845 4.25409 10.9885 4.74324 10.9926C3.28184 10.0335 2.65061 8.20905 3.20655 6.55122C3.26394 6.39013 3.40181 6.27112 3.5695 6.23794C3.73718 6.20475 3.90996 6.26228 4.02432 6.38939C5.99639 8.48825 8.70604 9.73896 11.5819 9.87779C11.5083 9.58385 11.4721 9.28176 11.4741 8.97872C11.501 7.38972 12.4842 5.97421 13.9634 5.39487C15.4425 4.81554 17.1249 5.18703 18.223 6.33544C18.9714 6.19285 19.695 5.94145 20.3707 5.58921C20.4202 5.55831 20.483 5.55831 20.5325 5.58921C20.5634 5.63873 20.5634 5.70152 20.5325 5.75104C20.2052 6.50052 19.6523 7.12912 18.9509 7.54919C19.5651 7.47796 20.1685 7.33307 20.7482 7.11764C20.797 7.08442 20.8611 7.08442 20.9099 7.11764C20.9508 7.13634 20.9814 7.17211 20.9935 7.21542C21.0056 7.25873 20.998 7.30518 20.9728 7.3424Z"
        className="fill-black"
      />
    </svg>
  );
};

export default Twitter;
