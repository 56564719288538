import React from "react";

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      className="fill-none w-6 h-[1.5625rem]"
    >
      <path
        d="M16.5 6.625H13.5C12.9477 6.625 12.5 7.07272 12.5 7.625V10.625H16.5C16.6137 10.6225 16.7216 10.6754 16.7892 10.7669C16.8568 10.8584 16.8758 10.977 16.84 11.085L16.1 13.285C16.0318 13.4869 15.8431 13.6234 15.63 13.625H12.5V21.125C12.5 21.4011 12.2761 21.625 12 21.625H9.5C9.22386 21.625 9 21.4011 9 21.125V13.625H7.5C7.22386 13.625 7 13.4011 7 13.125V11.125C7 10.8489 7.22386 10.625 7.5 10.625H9V7.625C9 5.41586 10.7909 3.625 13 3.625H16.5C16.7761 3.625 17 3.84886 17 4.125V6.125C17 6.40114 16.7761 6.625 16.5 6.625Z"
        className="fill-black"
      />
    </svg>
  );
};

export default Facebook;
