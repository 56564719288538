import React from "react";

const LinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      className="fill-none w-6 h-[1.5625rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.625H19C20.1046 3.625 21 4.52043 21 5.625V19.625C21 20.7296 20.1046 21.625 19 21.625H5C3.89543 21.625 3 20.7296 3 19.625V5.625C3 4.52043 3.89543 3.625 5 3.625ZM8 18.625C8.27614 18.625 8.5 18.4011 8.5 18.125V11.125C8.5 10.8489 8.27614 10.625 8 10.625H6.5C6.22386 10.625 6 10.8489 6 11.125V18.125C6 18.4011 6.22386 18.625 6.5 18.625H8ZM7.25 9.625C6.42157 9.625 5.75 8.95343 5.75 8.125C5.75 7.29657 6.42157 6.625 7.25 6.625C8.07843 6.625 8.75 7.29657 8.75 8.125C8.75 8.95343 8.07843 9.625 7.25 9.625ZM17.5 18.625C17.7761 18.625 18 18.4011 18 18.125V13.525C18.0325 11.9358 16.8576 10.5795 15.28 10.385C14.177 10.2843 13.1083 10.7994 12.5 11.725V11.125C12.5 10.8489 12.2761 10.625 12 10.625H10.5C10.2239 10.625 10 10.8489 10 11.125V18.125C10 18.4011 10.2239 18.625 10.5 18.625H12C12.2761 18.625 12.5 18.4011 12.5 18.125V14.375C12.5 13.5466 13.1716 12.875 14 12.875C14.8284 12.875 15.5 13.5466 15.5 14.375V18.125C15.5 18.4011 15.7239 18.625 16 18.625H17.5Z"
        className="fill-black"
      />
    </svg>
  );
};

export default LinkedIn;
